/*
-------------------------------------------------------------------
- VARIABLES :: Spaces
-------------------------------------------------------------------
*/
$accessibility-icon-margin:	0 4px 0 0;

/*
-------------------------------------------------------------------
- VARIABLES :: Theme colors
-------------------------------------------------------------------
*/
$white-color:			    #ffffff;
$black-color:				#000000;


$theme-base-color:			#2F2A2B;
$theme-alt-color:			#ee7501;
$theme-mono-color:			#919191;
$theme-error-color:			#ff0000;
$theme-color-1:			    #ee7501;
$theme-color-2:			    #035621;
$theme-color-3:			    #EFBB70;

$dark-color:                #222222;
$gray-color1:               #565656;
$gray-color2:               #A9A9A9;
$gray-color3:              #f3f3f3;

/*
-------------------------------------------------------------------
- VARIABLES :: Bootstrap colors
-------------------------------------------------------------------
*/
$body-bg:               	$white-color;
$text-color:            	$black-color;

/*
-------------------------------------------------------------------
- VARIABLES :: Links
-------------------------------------------------------------------
*/
$link-color:				$theme-alt-color;
$link-hover-color:      	$theme-alt-color;

/*
-------------------------------------------------------------------
- VARIABLES :: Typography
-------------------------------------------------------------------
*/

/*
$font-family-gaspo-light: 'GaspoSlab-Light';
$font-family-gaspo-bold: 'GaspoSlab-Bold';
$font-family-gaspo-black: 'GaspoSlab-Black';
$font-family-brandon-bold: 'BrandonTextWeb-Bold';
*/
/*
$font-family-nexa-thin          : 'Nexa Thin', Helvetica, Arial, sans-serif;
$font-family-nexa-regular       : 'Nexa Regular', Helvetica, Arial, sans-serif;
$font-family-nexa-bold          : 'Nexa Bold', Helvetica, Arial, sans-serif;
$font-family-nexa-light         : 'Nexa Light', Helvetica, Arial, sans-serif;
*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

$font-family-nexa-thin          : 'Open Sans', Helvetica, Arial, sans-serif;
$font-family-nexa-regular       : 'Open Sans', Helvetica, Arial, sans-serif;
$font-family-nexa-bold          : 'Open Sans', Helvetica, Arial, sans-serif;
$font-family-nexa-light         : 'Open Sans', Helvetica, Arial, sans-serif;

//$font-family-grotesque-black:       'Brandon Grotesque Black', Helvetica, Arial, sans-serif;
//$font-family-grotesque-bold:        'Brandon Grotesque Bold', Helvetica, Arial, sans-serif;
//$font-family-brandon-regular:       'Brandon Text Regular', Helvetica, Arial, sans-serif;
//$font-family-brandon-italic:        'Brandon Text Italic', Helvetica, Arial, sans-serif;
//$font-family-brandon-light:         'Brandon Text Light', Helvetica, Arial, sans-serif;
//$font-family-brandon-bold:          'Brandon Text Bold', Helvetica, Arial, sans-serif;



$font-family-sans-serif:					$font-family-nexa-regular;
$font-family-serif:						    $font-family-nexa-bold , Helvetica, Arial, sans-serif;
$font-family-base:						    $font-family-sans-serif;
$font-size-base:							16px;
$line-height-base:						    1.75;

$font-family-title: $font-family-nexa-regular;

/*
-------------------------------------------------------------------
- VARIABLES :: Iconography
-------------------------------------------------------------------
*/
//$icon-font-path:          	"/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts";
//$fa-font-path:        		"/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts";

/*
-------------------------------------------------------------------
- VARIABLES :: Components
-------------------------------------------------------------------
*/
//$list-indent:               24px;

/*
-------------------------------------------------------------------
- VARIABLES :: Forms
-------------------------------------------------------------------
*/
//$input-bg:                      	#F5F5F5;
//$input-border:				    #F5F5F5;
//$input-color:                   	#979797;
//$input-border-radius:       		0;
//$input-color-placeholder:       	#D2D2D2;
//$input-height-base:				50px;
//$input-border-focus:            	$theme-base-color;

/*
-------------------------------------------------------------------
- VARIABLES :: Panels
-------------------------------------------------------------------
*/
$panel-inner-border:                transparent;
$panel-border-radius:               0;
$panel-default-heading-bg:          transparent;
$panel-bg:                          transparent;
$panel-heading-padding:             10px 0;
$panel-primary-text:                $theme-alt-color;
$panel-default-border:              transparent;

/*
-------------------------------------------------------------------
- VARIABLES :: Components
-------------------------------------------------------------------
*/
//$border-radius-base:        	0;

/*
-------------------------------------------------------------------
- VARIABLES :: Grid system
-------------------------------------------------------------------
*/
$grid-gutter-width:         30px !default;

/*
-------------------------------------------------------------------
- VARIABLES :: Media queries breakpoints
-------------------------------------------------------------------
*/
$screen-md:   992px;
$screen-lg:   1200px;
$screen-xl:	  1600px;
$screen-xxl:  2000px;
$screen-ul:	  2500px;

/*
-------------------------------------------------------------------
- VARIABLES :: Extra media queries breakpoints
-------------------------------------------------------------------
*/
$screen-xl-min:              			$screen-xl;
$screen-xxl-min:             			$screen-xxl;
$screen-ul-min:              			$screen-ul;
$screen-lg-max:              			($screen-xl-min - 1);
$screen-xl-max:              			($screen-xxl-min - 1);
$screen-xxl-max:             			($screen-ul-min - 1);
$container-extra-large-desktop:         (($screen-xl - 60px + $grid-gutter-width));
$container-extra-extra-large-desktop:   (($screen-xxl - 60px + $grid-gutter-width));
$container-ultra-large-desktop:         (($screen-ul - 60px + $grid-gutter-width));
$container-xl:               			$container-extra-large-desktop;
$container-xxl:              			$container-extra-extra-large-desktop;
$container-ul:               			$container-ultra-large-desktop;


/*
-------------------------------------------------------------------
- VARIABLES :: Variables for individual sites
-------------------------------------------------------------------
*/


$height-header : 90px;
$height-header-home : 567px;
$height-header-page : 500px;





$bk-top-header:                         $white-color;
$link-top-header :                      $theme-base-color;
$link-top-header-hover :                $theme-base-color;


/*
-------------------------------------------------------------------
- VARIABLES :: Buttons
-------------------------------------------------------------------
*/

//$btn-font-weight:                normal !default;

//$btn-default-color:              #333 !default;
//$btn-default-bg:                 #fff !default;
//$btn-default-border:             #ccc !default;
//
//$btn-primary-color:              #fff !default;
//$btn-primary-bg:                 $brand-primary !default;
//$btn-primary-border:             darken($btn-primary-bg, 5%) !default;
//
//$btn-success-color:              #fff !default;
//$btn-success-bg:                 $brand-success !default;
//$btn-success-border:             darken($btn-success-bg, 5%) !default;
//
//$btn-info-color:                 #fff !default;
//$btn-info-bg:                      transparent;
//$btn-info-border:                darken($btn-info-bg, 5%) !default;
//
//$btn-warning-color:              #fff !default;
//$btn-warning-bg:                 $brand-warning !default;
//$btn-warning-border:             darken($btn-warning-bg, 5%) !default;
//
//$btn-danger-color:               #fff !default;
//$btn-danger-bg:                  $brand-danger !default;
//$btn-danger-border:              darken($btn-danger-bg, 5%) !default;
//
//$btn-link-disabled-color:        $gray-light !default;
//
//// Allows for customizing button radius independently from global border radius

$btn-border-radius-base:         10px;
$btn-border-radius-large:        10px;
$btn-border-radius-small:        10px;
