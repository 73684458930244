$red1: #F2DEDE;
$red2: rgba(240, 91, 89, 0.47);
$red3: #A94442;
$orange1: #FCF8E3;
$orange2: #FAEBCC;
$orange3: #FFCA4B;
$green1: #CDEACA;
$green2: #58B548;
$green3: #3B7826;


.tx-powermail {
    *:focus{
        outline: none;
        border:1px solid #acacac !important;
    }
    .powermail_fieldwrap{
        width: 100%;
        float: left;
        margin-bottom: 0.8em;
    }
    .fieldset-bg-no-legend{
        &>legend{
            display: none;
        }
    }
    .powermail_captcha,
    .powermail_input,
    textarea{
        float: left;
        width: 100%;
        padding: 0 0.5em;
        width: 100%;
        height: 42px;
        padding: 6px 12px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        //padding-bottom: 15px;
        &.form-group-50-mleft-powermail-pickadate,
        &.form-group-50-mleft{
            width: 50%;
            padding-left: 2%;
        }
        &.form-group-50-mright-powermail-pickadate,
        &.form-group-50-mright{
            width: 50%;
            padding-right: 2%;
        }
        &.form-group-50-blank{
            width: 100%;
            padding-right: 52%;
        }
        &.form-group-30-blank{
            width: 100%;
            padding-right: 70%;
        }
        border:1px solid  #dcdcdc;
    }
    .powermail_captcha{
        width:20%;
        margin-right:0.5em;
        height: 55px;
        line-height: 55px;
    }
    textarea{
        padding: 0.5em;
        height: 10em;
        line-height: 1.5em;
    }
    .powermail_field{
        .radio,
        .checkbox{
            width: auto;
            float: left;
            margin-top: 0px;
            input{
                margin-top: 0.5em;
            }
            & + .radio{
                margin-top: 0px;
                margin-left: 1em;
            }
            label{
                vertical-align: middle;
            }
        }
    }
    .form-control{
        background-color: rgba(240, 240, 240, 0.42);
        //border: 1px solid rgba(0, 0, 0, 0.11);
        border: 1px solid #dcdcdc;
    }

    label{
        //@include cff-ProximaNova(Semibold);
    }

    .checkbox label,
    .radio label {
        padding-left: 26px;
        //@include cff-ProximaNova(Regular);
    }
    .checkbox-inline input[type=checkbox],
    .checkbox input[type=checkbox],
    .radio-inline input[type=radio],
    .radio input[type=radio]{
        margin-left: -26px;
    }

    .powermail-title-legend1{
        //@include cff-DaxWide(ExtraBold);
        font-size: 1.0em;
        color: #282828;
        letter-spacing: 3px;
        line-height: 28px;
        text-transform: uppercase;
        /* padding-bottom: 25px; */
        padding-top: 20px;
        border-bottom: 1px solid #d3d3d3;
        margin-bottom: 25px;
    }
    .powermail-title-legend2{
        //@include cff-DaxWide(ExtraBold);
        font-size: 1.0em;
        color: #282828;
        letter-spacing: 3px;
        line-height: 28px;
        text-transform: uppercase;
        padding-top: 20px;
        margin-bottom: 5px;
    }

    .powermail_fieldset{
        margin-bottom: 30px;
        &.fieldset-bg-no-legend{
            background-color: rgba(228, 228, 228, 0.24);;
            padding: 25px;
            //border: 1px solid rgba(0, 0, 0, 0.11);
            border: 1px solid #dcdcdc;
            .form-control{
                background-color: #ffffff;
            }
        }
        &.fieldset-bg-legend{
            background-color: rgba(228, 228, 228, 0.24);;
            padding: 25px;
            //border: 1px solid rgba(0, 0, 0, 0.11);
            border: 1px solid #dcdcdc;
            .form-control{
                background-color: #ffffff;
            }
        }
        &.fieldset-button{
            margin-top: 40px;
            width: 100%;
        }
    }

    /*
    .powermail_message{
        &.powermail_message_error{
            color: $color7;
            background: #fbddd5;
            padding: 20px;
            li{
                margin-left: 30px;
            }
        }
    }
    */
    .powermail_message {
        padding: 10px 0 10px 20px;
        min-height: 65px;
        background-color: $red1;
        border: 1px solid $red2;
        background-position: 98% 10px;
        background-repeat: no-repeat;
        list-style: none;

        li {
            padding: 5px 50px 0 0;
            border: none;
            background: none;
            &:before{
                display: none;
            }
        }

        &.powermail_message_ok {

            background-color: $green1;
            border: 1px solid $green3;
            li {
                color: $green3;
            }
        }

        &.powermail_message_error {

            li {
                color: $red3;
            }
        }

        &.powermail_message_note {

            background-color: $orange1;
            border: 1px solid $orange3;
            li {
                color: $orange3;
            }
        }
    }

    .powermail_confirmation{
        margin-top: 30px;
        h3{
            margin-bottom: 20px;
        }
        .table{
            width: 100%;
            margin-bottom: 50px;
            border-bottom: 1px solid #ddd;
            tr>td{
                line-height: 1.5em;
                &:nth-child(1) {
                    width: 35%;
                    //@include cff-ProximaNova(Semibold);
                    letter-spacing: 1px;
                    font-size: 16px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    -ms-word-break: break-all;
                    word-break: break-all;
                    word-break: break-word;
                    -ms-hyphens: auto;
                    -moz-hyphens: auto;
                    -webkit-hyphens: auto;
                    hyphens: auto;
                }
                &:nth-child(2){
                    font-size: 16px;
                    width: 65%;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    -ms-word-break: break-all;
                    word-break: break-all;
                    word-break: break-word;
                    -ms-hyphens: auto;
                    -moz-hyphens: auto;
                    -webkit-hyphens: auto;
                    hyphens: auto;
                }
            }
        }


        .btn-group{
            width: 100%;
            position: relative;
            form{
                width: 50%;
                text-align: left;
                float: left;
            }
        }
        .wrap-powermail-submit{
            position: relative;
            text-align: right;
        }
        .wrap-powermail-reset{
            position: relative;
            text-align: left;
        }

    }

    .has-error .form-control {
        border-color: #a94442;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    }

    textarea,
    input[type=text] {

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }







    .powermail_form {
        .form-control {
            width: 100%;
            height: 42px;
            padding: 6px 12px;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
            -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        h3{
            display: none;
        }
        .powermail_label {
            .mandatory {
                display: inline-block;
                margin-left: 4px;
                color: #b61a1a;
            }
        }
        .powermail_field {
            .parsley-errors-list {
                display: inline-block;
                margin: 0;
                padding: 2px 0 0 0;
                width: 100%;
                list-style: none;
                background-color: #b61a1a;
                > li {
                    list-style: none;
                    display: inline-block;
                    width: 100%;
                    color: $white-color;
                    font-size: 13px;
                    &:before{
                        position: absolute;
                        left: 7px;
                        top: 14px;
                        content: "-";
                        color: $white-color;
                        font-size: 20px;
                        line-height: 0;
                    }
                }
            }
        }
        .form-control {
            border-radius: 0;
            &.parsley-error {
                border-color: #ff9b9b;
            }
        }
    }
}




@media screen and (max-width: $screen-xs-max) {
    .tx-powermail {

        .form-group {
            float: left;
            width: 100%;
            //padding-bottom: 15px;
            &.form-group-50-mleft-powermail-pickadate,
            &.form-group-50-mleft {
                width: 100%;
                padding-left: 0;
            }
            &.form-group-50-mright-powermail-pickadate,
            &.form-group-50-mright {
                width: 100%;
                padding-right: 0;
            }
            &.form-group-50-blank {
                width: 100%;
                padding-right: 0;
            }
            &.form-group-30-blank {
                width: 100%;
                padding-right: 0;
            }
        }

        .powermail_confirmation {
            margin-top: 20px;
            .table {
                width: 100%;
                margin-bottom: 50px;
                border-bottom: 1px solid #ddd;
                tr > td {
                    line-height: 1.7em;
                    &:nth-child(1) {
                        width: 100%;
                        letter-spacing: 1px;
                        font-size: 17px;
                        float: left;
                    }
                    &:nth-child(2) {
                        font-size: 17px;
                        width: 100%;
                        float: left;
                        border-bottom: 1px solid $black-color;
                    }
                }
            }
        }
    }
}





/*
.tx-powermail {

    .powermail_form {

        .powermail_fieldset {

        }

        .powermail_fieldwrap {
            padding: 0 0 15px 0;

            &.powermail_fieldwrap_type_submit {
                padding: 20px 0 0 0;

                text-align: right;
            }
        }

        .powermail_label {
            margin-bottom: 3px;

            .mandatory {
                display: inline-block;
                margin-left: 4px;

                color: #b61a1a;
            }
        }

        .powermail_field {

            .parsley-errors-list {
                display: inline-block;
                margin: 0;
                padding: 0;
                width: 100%;
                background-color: #b61a1a;

                list-style: none;

                > li {
                    display: inline-block;
                    width: 100%;

                    color: #ffffff;
                    font-size: 11px;
                }
            }
        }

        .form-control {
            border-radius: 0;

            &.parsley-error {
                border-color: #ff9b9b;
            }
        }
    }

    .powermail_create {

    }
}
textarea,
input[type=text] {

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
*/