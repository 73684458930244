$cfi-download:                          "\e908";
$cfi-drop:                              "\e907";
$cfi-close:                             "\e900";
$cfi-download_arrow:                    "\e901";
$cfi-facebook_social:                   "\e902";
$cfi-plus:                              "\e903";
$cfi-twitter_social:                    "\e904";
$cfi-vimeo_social:                      "\e905";
$cfi-youtube_social:                    "\e906";


.cfi-download:before {
    content: $cfi-download;
}

.cfi-drop:before {
    content: $cfi-drop;
}

.cfi-close:before {
    content: $cfi-close;
}

.cfi-download_arrow:before {
    content: $cfi-download_arrow;
}

.cfi-facebook_social:before {
    content: $cfi-facebook_social;
}

.cfi-plus:before {
    content: $cfi-plus;
}

.cfi-twitter_social:before {
    content: $cfi-twitter_social;
}

.cfi-vimeo_social:before {
    content: $cfi-vimeo_social;
}

.cfi-youtube_social:before {
    content: $cfi-youtube_social;
}

//$cfi-content-external-link: 			$cfi-external-link;
//$cfi-content-external-link-new-window: 	$cfi-external-link;
//$cfi-content-internal-link: 			$cfi-internal-link;
//$cfi-content-internal-link-new-window: 	$cfi-internal-link;
$cfi-content-download: 					$cfi-download;
//$cfi-content-mail:						$cfi-mail;


@font-face {
    font-family: 'aro';
    src:  url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/aro.eot?8gmp9n');
    src:  url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/aro.eot?8gmp9n#iefix') format('embedded-opentype'),
    url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/aro.woff2?8gmp9n') format('woff2'),
    url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/aro.ttf?8gmp9n') format('truetype'),
    url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/aro.woff?8gmp9n') format('woff'),
    url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/aro.svg?8gmp9n#aro') format('svg');
    font-weight: normal;
    font-style: normal;
}

@mixin cfi-template {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'aro' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cfi-template, [class^="cfi-"], [class*=" cfi-"] {
    @include cfi-template;
}

@mixin cfi($icon) {

    &:before {
        @include cfi-template;
        content: $icon;
    }
}

@mixin cfi-switch($icon) {

    &:before {
        content: $icon;
    }
}

@mixin cfi-after($icon) {

    &:after {
        @include cfi-template;
        content: $icon;
    }
}

@mixin cfi-switch-after($icon) {

    &:after {
        content: $icon;
    }
}

@mixin cfi-margin($icon, $margin: 1.3em) {
    @include cfi($icon);

    &:before {
        display: inline-block;
        width: $margin;
        text-align: left;
    }
}

@mixin cfi-after-margin($icon, $margin: 1.3em) {
    @include cfi-after($icon);

    &:after {
        display: inline-block;
        width: $margin;
        text-align: right;
    }
}

@mixin cfi-indent($icon, $margin: 1.3em) {
    @include cfi($icon);

    &:before {
        display: inline-block;
        margin-left: -$margin;
        width: $margin;
        text-align: left;
    }
}

@mixin cfi-after-indent($icon, $margin: 1.3em) {
    @include cfi-after($icon);

    &:after {
        display: inline-block;
        margin-right: -$margin;
        width: $margin;
        text-align: right;
    }
}

@mixin cfi-size($size) {

    &:before {
        font-size: $size;
    }
}

@mixin cfi-after-size($size) {

    &:after {
        font-size: $size;
    }
}

@mixin cfi-style($rule, $value) {

    &:before {
        #{$rule}: $value;
    }
}

@mixin cfi-after-style($rule, $value) {

    &:after {
        #{$rule}: $value;
    }
}
