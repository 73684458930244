
/* ------------------------------------------------
*   TOP HEADER
* ------------------------------------------------ */


.top-header {
    position: fixed;
    width: 100%;
    background: $bk-top-header;
    top: 0;
    left: 0;
    right: 0;
    height: $height-header;
    transition: color 0.5s ease;
    z-index: 10000;
    color: $theme-mono-color;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);

    .top-header-wrap {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .top-header-items {
        display: table;
        table-layout: fixed;
        height: 100%;
        width: 100%;
        .top-header-item {
            display: table-cell;
            vertical-align: middle;
            height: 100%;

            &.top-header-menu {
                text-align: center;
            }

            &.top-header-actions {
                width: 260px;
                text-align: right;

                .btn {
                    min-width: 190px;
                }
            }
            &.top-header-language{
                width: 50px;
            }
            &.top-header-mobile{
                width: 100px;
                display: none;
                text-align: right;

                .hamburger {
                    margin-top: 5px;
                }
            }
        }
    }
}




@media screen and (max-width: $screen-lg-max) {
}

@media screen and (max-width: $screen-md-max) {
    .top-header {
        .top-header-items {
            .top-header-item {

            }
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    .top-header {
        .top-header-items {
            .top-header-item {
                &.top-header-mobile{
                    display: table-cell;

                }
                &.top-header-language{
                    width: auto;
                }
            }

        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .top-header {

    }
}






/* ------------------------------------------------
*   TOP HEADER MENU
* ------------------------------------------------ */


.top-header {

    .top-header-menu-items {
        float: right;
        width: auto;
        height: 100%;
        font-family: $font-family-nexa-regular;
        @include link-color($link-top-header, $link-top-header-hover);
    }

    .top-header-menu-item {
        display: table-cell;
        vertical-align: middle;
        position: relative;
        //height: 100%;
        padding: 0 35px 0 0;
        //margin: 0 15px;
        margin: 0;
        font-size: 15px;
        letter-spacing: 2px;
        text-transform: uppercase;
        //line-height: 17px;
        height: 20px;
        line-height: 20px;
        /* Special elements to help hover over other items, after was already used so it's disabled */
        &:before {
            display: none;
            position: absolute;
            content: ' ';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 40px 0 0 40px;
            border-color: transparent transparent transparent transparent;
            bottom: 0;
            left: 100%;
            right: auto;
            ///height: 45px;
            z-index: 1000;
        }
        &.active {
            color: $theme-alt-color !important;
        }
        /*
        &#top-header-menu-item-id-2{
            &:hover{
                color: $theme-color-1;
            }
            &.active {
                color: $theme-color-1;
            }
        }
        &#top-header-menu-item-id-3{
            &:hover{
                color: $theme-color-2;
            }
            &.active {
                color: $theme-color-2;
            }
        }
        &#top-header-menu-item-id-4{
            &:hover{
                color: $theme-color-1;
            }
            &.active {
                color: $theme-color-1;
            }
        }
        &#top-header-menu-item-id-5{
            &:hover{
                color: $theme-color-3;
            }
            &.active {
                color: $theme-color-3;
            }
        }
        */
    }


    .top-header-menu-item-title {
        display: table;
        vertical-align: middle;
        //display: inline-block;
        float: left;
        position: relative;
        height: 100%;
        line-height: 1;
        cursor: pointer;

        letter-spacing: 2px;
        //------- Add Bieri
        text-align: left;
        ///////

        &:hover {

            .top-header-menu-item {
                color: $theme-base-color;

                &:before,
                &:after {
                    display: block;
                }
            }

            .top-header-menu-item-container-wrap {
                //display: block;
                visibility: visible;
                transition: opacity 0.3s ease-in-out;
                opacity: 1;
            }

            .inner-container-menu {

                .inner-container-menu-item {
                    font-weight: normal;

                    &.active{
                        color:$theme_alt-color!important;
                    }
                }
            }
        }

        &.last {

            .top-header-menu-item-container-wrap {
                left: auto;
                right: 0;
            }
            .top-header-menu-item {
                padding-right: 0;
            }
        }
    }


}




@media screen and (max-width: $screen-lg-max) {
}

@media screen and (max-width: $screen-md-max) {
    .top-header {
        .top-header-menu-item {
            margin: 0 4px;
            font-size: 13px;
            letter-spacing: 1px;
        }

    }
}

@media screen and (max-width: $screen-sm-max) {
    .top-header {
        .top-header-menu-item {
            font-size: 11px;
        }
        .top-header-items {
            display: table;
            table-layout: fixed;
            height: 100%;
            width: 100%;
            .top-header-item {
                &.top-header-menu{
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .top-header {

    }
}





/* ------------------------------------------------
*   TOP HEADER SUB MENU
* ------------------------------------------------ */



.top-header{
    //Sottomenu
   .top-header-logo-wrap.top-header-item{
        img{
            width: 135px;
            display: inline-block;
            margin-top: 0.5em;
        }
    }
    .top-header-menu-item-container-wrap {
        background: #e4e4e4; /* Old browsers */
        background: -moz-linear-gradient(top,  #e4e4e4 0%, #ffffff 10%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #e4e4e4 0%,#ffffff 10%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #e4e4e4 0%,#ffffff 10%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4e4e4', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

        visibility: hidden;
        position: absolute;
        left: 0;
        right: auto;
        top: $height-header;
        //background-color: $white-color;
        opacity: 0;
        transition: visibility 0s linear 0.2s, opacity 0.2s ease-in-out;
        text-align: left;
        box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.15);
        clip: rect(0px, 1000px, 1000px, -10px);
        //padding: 40px 30px 40px 30px;
        padding: 40px 0px 40px 0px;
        /*
        &:before{
            position: absolute;
            content: '';
            width: 1px;
            height: 100%;
            left: 30px;
            top:0;
            background-color: rgba(255, 255, 255, 0.24);
        }
        */
        /*
        &#top-header-menu-item-container-wrap-2 {
            background-color: $theme-color-1;
        }
        &#top-header-menu-item-container-wrap-3 {
            background-color: $theme-color-2;
        }
        &#top-header-menu-item-container-wrap-5 {
            background-color: $theme-color-3;
        }
        */
    }



    .top-header-content {
        display: table-cell;
        vertical-align: middle;
        height: $height-header;
    }



    .top-header-menu-item-container-items {
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    .inner-container-menu {
        line-height: $line-height-base;
        //background: $theme-base-color;
        color: $white-color;
        height: 100%;
        font-size: 16px;
        letter-spacing: 0;
        font-family: $font-family-nexa-thin;
        .inner-container-menu-items {
            //text-align: right;
            @include this-link-color($theme-base-color,$link-hover-color);

        }

        .inner-container-menu-item {
            display: block;
            text-align: left;
            min-width: 345px;
            &:hover {
                .inner-container-menu-item-inner {
                    background: rgba(255, 255, 255, 0.11);
                  //              &:hover{
                //span{
                    color:$link-hover-color;
              //  }
            //}
                    &:before{
                        opacity: 1;
                        visibility: visible;
                        height: 18px;
                        top:0;
                    }
                    &:after{
                        opacity: 1;
                        visibility: visible;
                        height: 18px;
                    }
                }
            }

            &.active {

                .inner-container-menu-item-inner {
                    background: rgba(255, 255, 255, 0.11);
                }
            }

            .inner-container-menu-item-inner {
                //margin: 0 0 5px 0;
                margin: 0;
                //padding: 10px 20px 10px 20px;
                padding: 2px 15px 0 20px;
                //padding: 2px 45px 0 50px;
                display: block;
                height: 36px;
                line-height: 36px;
                position: relative;
                &:before{
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.5s ease;
                    content : '';
                    position: absolute;
                    left: 0;
                    top:50%;
                    width: 3px;
                    height: 0;
                    background-color: $theme-alt-color;
                }
                &:after{
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.5s ease;
                    content : '';
                    position: absolute;
                    left: 0;
                    top:50%;
                    width: 3px;
                    height: 0;
                    background-color: $theme-alt-color;
                }
            }

            &:first-child{
                .inner-container-menu-item-inner{
                    //margin: 0;
                }
            }
            &:last-child{
                .inner-container-menu-item-inner {
                    margin: 0 0 0 0;
                }
            }
        }
    }
    


}







/* ------------------------------------------------
*   TOP HEADER LOGO
* ------------------------------------------------ */


.top-header {
    .top-header-mobile-logo-wrap {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 99;
    }
    .top-header-items {
        font-weight: 600;
        .top-header-item {
            &.top-header-logo-wrap {
                width: 200px;
                .top-header-logo {
                    opacity: 1;
                    transition: opacity 0.5s ease;
                }
            }
        }
    }
}


@media screen and (max-width: $screen-lg-max) {
}

@media screen and (max-width: $screen-md-max) {
    .top-header {
        .top-header-items {
            .top-header-item {
                &.top-header-logo-wrap {
                    width: 170px;
                }
            }
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    .top-header {
        height: 70px;
        .top-header-items {
            .top-header-item {
                &.top-header-logo-wrap {
                    width: 107px;
                }
            }
        }
    }
}

@media screen and (max-width: $screen-xs-max) {

}

/*
@media
only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: $screen-sm-max) ,
only screen and (min-resolution: 192dpi) and (max-width: $screen-sm-max)  {
    .top-header{
        .top-header-items {
            .top-header-item {
                &.top-header-logo-wrap {
                    .top-header-logo {
                        display: block;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        background: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Images/Logos/logo@2x.png') no-repeat;
                        background-size: 107px 54px;
                        width: 107px;
                        height: 54px;
                        padding-left: 107px;
                    }
                }
            }
        }
    }
}
*/



/* ------------------------------------------------
 *   Lang Menu Top
 * ------------------------------------------------ */


.lang-menu {
    float: right;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        //font-family: $font-family-Futura-book;
        color: $black-color;
        letter-spacing: 2px;
        line-height: 30px;
        display: inline-block;
        padding-right: 10px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            right: 4px;
            top: 7px;
            width: 1px;
            height: 13px;
            background-color: $theme-base-color;
        }
        &:last-child {
            padding-right: 0;
            &:before {
                display: none;
            }
        }
        a {
            font-size: 13px;
            color: $black-color;
            text-decoration: none;
            text-transform: capitalize;
            &:hover {
                //color: $theme-color-hover;
                text-decoration: none;

            }
        }
    }
}



@media screen and (max-width: $screen-md-max) {
    .lang-menu {
        li {
            a {
                font-size: 13px;
            }
        }
    }
	img.top-header-logo.img-responsive{
		max-height: 65px;
	}
}

@media screen and (max-width: $screen-xs-max) {
    .lang-menu {
        //display: none;
        //position: absolute;
        //top: -54px;
        //right: 95px;
        //z-index: 1000;
        li {
            //color: $theme-mono-color;
            a {
                //color: $theme-mono-color;
            }
        }
    }
}