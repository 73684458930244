

// Use body to augment specificity
.btn {
    //.cff-Arvo();
    text-transform: uppercase;
    transition: all .3s ease-in-out;

}

.btn-default,
.btn-primary,
.btn-alt {
    //min-width: 180px;

    text-decoration: none !important;
}
.btn-no-min{
    min-width: inherit;
}

.btn + .btn {
    margin-left: 10px;
}

.btn-default {
    //.button-variant-2(@btn-default-color; @btn-default-bg; @btn-default-border; @theme-dark-base-color; @white-color; @theme-dark-base-color);
}

.btn-primary {
    //.button-variant-2(@btn-primary-color; @btn-primary-bg; @btn-primary-border; @white-color; @theme-alt-color; @theme-alt-color);
}

.btn-alt {
    //.button-variant-2(@white-color; #f49600; #f49600; @white-color; darken(#f49600, 10%); darken(#f49600, 10%));
}



.btn-primary {
    @include button-variant-default($white-color, $theme-base-color, $theme-base-color);
}

.btn-default {
    @include button-variant-default(transparent, $white-color, $white-color);
}







.btn {
    /* NEWSLETTER ANMELDEN: */
    //font-family: $font-family-Montserrat;
    font-weight: 700;
    font-size: 13px;
    color: $white-color;
    letter-spacing: 0px;
    line-height: 15px;
    text-transform: uppercase;
    padding: 10px 30px;


    &.btn-white {
        border-width: 1px;
        padding: 10px 40px;
        background-color: transparent;
        color: $white-color;
        border-color: $white-color;
        letter-spacing: 3px;
        font-size: 14px;
        &:hover{
            background-color: $white-color;
            color: $black-color;
        }
    }
    &.btn-orange {
        border-width: 1px;
        padding: 10px 40px;
        background-color: transparent;
        color: $theme-base-color;
        border-color: $theme-base-color;
        letter-spacing: 3px;
        font-size: 14px;
        &:hover{
            background-color: $theme-base-color;
            color: $white-color;
        }
    }
    /*
    &.btn-dark {
        border-width: 1px;
        padding: 10px 40px;
        background-color: transparent;
        color: $color-blue-dark;
        border-color: $color-blue-dark;
        letter-spacing: 3px;
        font-size: 14px;
        &:hover{
            background-color: $color-blue-dark;
            color: $white-color;
        }
    }
    */

    &.button-modal-full {
        margin: 0 0 1.471em;
    }
}



/*

Use the standard .btn + btn-default or btn-primary class

.btn-davos-border {
.button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
color: @colorBase2;

padding: 10px 40px;
.cff-Arvo();
font-size: 1.176em;
&:hover,
&:focus {
    border-color: @colorBase17;
    color: @colorBase17;
    background-color: transparent;
    text-decoration: none!important;
}
}
.btn-davos-full {
.button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
}
*/





@media screen and (max-width: $screen-xxl-max) {
}

@media screen and (max-width: $screen-xl-max) {
}

@media screen and (max-width: $screen-lg-max) {
}

@media screen and (max-width: $screen-md-max) {
.btn {

    &.btn-white {
        letter-spacing: 2px;
    }
    &.btn-blue {
        letter-spacing: 2px;
    }
    &.btn-dark {
        letter-spacing: 2px;
    }


}

}

@media screen and (max-width: $screen-sm-max) {
}

@media screen and (max-width: $screen-xs-max) {
.btn + .btn {
    margin-left: 0;
}

.btn{
    &.button-modal-full {
        min-width: 100px;
    }
}
}


