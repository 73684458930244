/* ------------------------------------------------
 *   FOOTER
 * ------------------------------------------------ */

.footer {

    border-top: 1px solid #dcdcdc;
    padding: 10px 0 35px;
    font-size: 0.9em;
    background-color: $white-color;
    color: $black-color;

    a{
        color:$black-color;
        &:hover{
            color:$theme-alt-color;
        }
    }

    &.footer-home {

    }

    &.footer-page {

    }

    p,
    .fce {
        margin: 0;
    }

    .footer-title {
        padding-top: 35px;
        padding-bottom: 8px;
        color: $theme-alt-color;
        font-size: 1em;
        letter-spacing: 2.5px;
        line-height: 35px;
        text-transform: uppercase;
        font-weight: 300;
    }
    .footer-sub-title{
        font-family: $font-family-nexa-bold;
        font-weight: 600;
    }
    .footer-address {

        &.footer-address-top {

        }

        &.footer-address-bottom {
            padding-top: 0;
        }
    }

    .footer-socials {
        padding-top: 15px;

        .footer-social {
            padding-right: 5px;
        }
    }

    .footer-social-links{
        margin-top: 35px;
        a{
            margin-right: 13px;
        }
        a+a{
            margin-right: 0;
        }
        path{
            fill : $theme-base-color;
        }
    }

    .footer-service-links {
        padding-top: 15px;

        .footer-service-link {
            display: block;
            font-weight: bold;
            font-size: 13px;
            letter-spacing: 1.5px;
            line-height: 35px;
            text-transform: uppercase;
        }
    }

    .download-action {

    }

    svg.schweiz{
        width: 100%;
        //height: auto;
        height: 100%;
        path{
            stroke: $theme-alt-color !important;
        }
    }
}



@media screen and (max-width: $screen-sm-max) {
    .footer {

        .download-action {
            padding: 12px 25px;
        }
    }
}

@media screen and (max-width: $screen-xs-max) {

    .footer {

        .download-action {
            padding: 12px 20px;

            width: 100%;
            white-space: normal;
            text-align: center;
        }

        padding: 10px 0 35px;
        font-size: 14px;

        .footer-title {
            padding-top: 20px;
            padding-bottom: 4px;
            font-size: 14px;
            letter-spacing:1px;
            line-height: 35px;
        }
    }
}


/* ------------------------------------------------
*   FOOTER ENDING
* ------------------------------------------------ */


.ending {
    border-top: 1px solid #dcdcdc;
    padding: 15px 0;
    color: $theme-base-color;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 35px;
    text-align: center;
    background-color: $white-color;

    &.ending-home {

    }

    &.ending-page {

    }

    .ending-links {
        color: $theme-base-color;
        font-size: 13px;
        letter-spacing: 2px;

        .ending-link {

        }

        .ending-separator {
            display: inline-block;
            padding: 0 5px;
        }
    }
}

/* ------------------------------------------------
*   FOOTER NAV
* ------------------------------------------------ */

#nav-footer{
    list-style: none;
    margin-top: 20px;
    padding: 0;
    display: inline-block;
    li{
        display: inline-block;
        padding: 0;
        a{
            font-family: $font-family-nexa-light;
            font-size: 13px;
            color: $gray-color1;
            letter-spacing: 1.5px;
            line-height: 32px;
        }
    }
    li + li{
        padding-left: 15px;
    }
}


@media screen and (max-width: $screen-lg-max) {
}

@media screen and (max-width: $screen-md-max) {
}

@media screen and (max-width: $screen-sm-max) {
}

@media screen and (max-width: $screen-xs-max) {
    #nav-footer{
        li{
            a{
                font-size: 12px;
                letter-spacing: 0;
            }
        }
        li + li{
            padding-left: 10px;
        }
    }
}