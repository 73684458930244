.cright {
    position: absolute;
    left: -26px;
    bottom: 54px;
    padding: 0.5em;
    color: white;
    transform: rotate(-90deg);
    opacity: 0.7;
}

.header {
    position: relative;
    margin-top: $height-header;
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;

    &.header-home {
        height: $height-header-home;
    }

    &.header-page {
        height: $height-header-page;
    }

    &.no-background-image {
        height: auto !important;
    }

    .header-text-wrap {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: left;

        .header-text {
            color: $white-color;
            font-family: $font-family-nexa-thin;
            //color: $theme-base-color;
            text-shadow: rgba(0, 0, 0, 0.7) 1px 2px 7px;
            font-size: 45px;
            line-height: 54px;
        }
    }

    .header-background {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .header-background-item {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            &.header-background-item-map {
                text-align: center;
            }

            &.header-background-item-dark {
                &:before {
                    content: '';
                    height: 100%;
                    width: 100%;
                    //background-color: rgba(0, 0, 0, 0.65);
                    background-color: rgba(0, 0, 0, 0.30);
                    display: block;
                }
            }
        }

        .owl-stage-outer {
            height: 100%;

            .owl-stage {
                height: 100%;

                .owl-item {
                    height: 100%;
                }
            }
        }

        .owl-dots {
            position: absolute;
            bottom: 0;
            text-align: center;
            width: 100%;

            .owl-dot {
                span {
                    width: 12px;
                    height: 12px;
                    background: transparent;
                    border: 1px solid #C8C8C8;
                    border-radius: 12px;
                    -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
                }

                &.active,
                &:hover {
                    span {
                        background: $white-color;
                    }
                }
            }
        }

        .owl-dots {
            padding-top: 10px;
        }

        .owl-nav {
            display: none;
        }

        /*
        .owl-nav {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            z-index: 9999;
            text-align: center;

            .owl-prev,
            .owl-next {
                position: absolute;
                top: 0;
                margin: 0px;
                border-radius: 0px;
                width: 54px;
                height: 54px;
                background: rgba(255, 255, 255, 0.40);
                color: $white-color;
                line-height: 54px;
                font-size: 20px;
                cursor: pointer;

                > i {
                    display: inline-block;
                    vertical-align: top;
                    padding-top: 17px;
                }
            }

            .owl-prev {
                left: 0;

                > i {
                    padding-left: 3px;
                }
            }

            .owl-next {
                right: 0;

                > i {
                    padding-right: 3px;
                }
            }
        }
        */
    }

    .header-background-map-wrap {
        position: relative;
        display: inline-block;

        .header-background-map {}
    }

    .header-overlay {
        width: 100%;
        height: 100%;
    }
}


@media screen and (max-width: $screen-lg-max) {}

@media screen and (max-width: $screen-md-max) {
    .header {
        &:focus {
            visibility: visible;
        }

        &.header-home {
            height: 420px;
        }

        &.header-page {
            height: 360px;
        }

        .header-text-wrap {

            .header-text {
                font-size: 36px;
                line-height: 46px;
            }
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    .header {
        margin-top: 70px;

        &.header-home {
            height: 280px;
        }

        &.header-page {
            height: 280px;
        }

        .header-text-wrap {

            .header-text {
                font-size: 28px;
                line-height: 36px;
            }
        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .header {
        margin-top: 70px;

        .header-background {
            .header-background-item {
                background-position: 100% center;
            }
        }

        .owl-item {


            &:nth-child(4) {
                .header-background-item {
                    background-position: center center;
                }
            }
        }



        .header-text-wrap {
            //left: 20px;
            //right: auto;
            //top: 10px;
            //transform: none;

            .header-text {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}



/* ------------------------------------------------
*   HEADER MOBILE
* ------------------------------------------------ */

/*
.header {
    .header-background-map-mobile-wrap {
        display: none;
        height: 240px;
        padding-top: 75px;

        .header-map-mobile-controls {
            display: inline-table;
        }

        .header-map-mobile-control-wrap {
            display: table-cell;
            vertical-align: top;
            text-align: center;

            &.active {

                .header-map-mobile-control {
                    background: $theme-base-color;
                }

                .header-map-mobile-control-line {
                    background: $theme-base-color;
                }

                .header-map-mobile-control-text {
                    color: $theme-base-color;
                }
            }
        }

        .header-map-mobile-control-line {
            display: inline-block;
            position: absolute;
            width: 2px;
            height: 20px;
            bottom: -30px;
            background: #D8D8D8;
            transition: all 0.5s ease-in-out;

            &.top {
                top: -30px;

                .header-map-mobile-control-text {
                    bottom: 30px;
                }
            }
        }

        .header-map-mobile-control-text {
            display: inline-block;
            position: absolute;
            left: -50px;
            right: -50px;
            bottom: -40px;
            width: 100px;
            font-size: 13px;
            font-weight: bold;
            line-height: 16px;
            color: #D8D8D8;
            transition: all 0.5s ease-in-out;
        }
    }

}


@media screen and (max-width: $screen-lg-max) {
}

@media screen and (max-width: $screen-md-max) {
}

@media screen and (max-width: $screen-sm-max) {
}

@media screen and (max-width: $screen-xs-max) {
    .header {
        .header-background-map-mobile-wrap {
            display: block;
            float: left;
            width: 100%;
            margin-bottom: 25px;
        }
    }
}
*/