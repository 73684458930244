html {
    width: 100%;
    height: 100%;

    &.navigation-visible,
    &.offcanvas-visible {
        overflow: hidden;

        body {
            overflow: hidden;
            height: 100%;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
    #kekschecker .cookies-overlay__content p {
      margin-bottom: 20px;
    }
}

.setdistance + .setdistance{
    padding-top: 4em;
}
@keyframes scroll {
    0%  {
        opacity: 0;
        transform:translateY(0);
    }
    50%  {
        opacity: 1;
        transform:translateY(5px);
    }
    100% {
        opacity: 0;
        transform:translateY(10px);
    }
}

body {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;



    .nlink-off {
        cursor: default;
        pointer-events: none;
    }

    a,
    a:link,
    a:focus,
    a:active {
        text-decoration: none;
        outline: none;
        outline-style: none;
    }


    a:hover{
        text-decoration: underline;
    }
    

    p {
        a {
            font-weight: $font-family-nexa-bold;
        }
    }

    .print-logo  {
        display: none;
    }

    .height-full {
        height: 100%;
    }

    .static-element {
        position: static;
    }

    .container-center {
        position: relative;
        padding-top: 10px;
    }

    .btn {
        padding: 12px 45px 11px 45px;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
        background: $theme-alt-color;
        border-radius: 4px;
        width:auto;
        display: inline-block !important;
        margin-top: 1em;

        &:hover {
            text-decoration: none !important;
            background: darken($theme-alt-color,10);
        }

        &.btn-primary {

        }

        &.btn-full {
            width: 100%;
        }
    }
    .content-main-right{
        .btn{
            background:none;
            border:1px solid $theme-alt-color;
            color:$theme-alt-color;
            width: auto !important;
            &:hover{
                background: $theme-alt-color;
                color:$white-color;
            }
        }
    }

    .panel {
        box-shadow: none;

        .panel-heading {
            color: $theme-alt-color;
            //////////font-family: $font-family-gaspo-black;
            font-size: 30px;
            line-height: 38px;
        }
    }

    .panel-body {
        padding: 0;

        .content-main {
            padding: 0;

            > .container {
                padding: 0;
            }

            .top-page {
                
                h1 {
                    display: none;
                }
            }
        }
    }
    .content-main-right{
        .news{
            border:2px solid $gray-color3;
            border-radius: 4px;
            padding: 1em 2em;
            float: left;
            .news-list-category{
                color: $theme-alt-color;
                font-weight: 300;
                line-height: 1.1875em; 
                font-size: 2em;     
                text-transform: none;          
            }
            .news-list-date{
                border-top: 2px solid  $gray-color3;
                padding-top: 2em;
                margin-top: 2em;
            }
            time{
                color: $theme-alt-color;
                font-size: 0.9em;
            }
            h3{
                span{
                    font-weight: 700;
                    font-size: 1.6rem !important;
                    color: $theme-alt-color;
                }
            }
        }
    }
    /*
        p {
            margin: 15px 0        }
    /*
        .scrollTo {
            display: block;
            position: absolute;
            bottom: 30px;
            left: 50%;
            width: 250px;
            margin-left: -125px;
            text-align: center;
            background: none;
            z-index: 1;
            outline: none;
            border: 0;
            color: #fff;
            height: 80px;

            span {
                display: block;
                padding-bottom: 5px;
                font-size: 12px;
                letter-spacing: 6px;
                text-transform: uppercase;
            }

            svg {
                width: 20px;
                height: auto;

                .scroller {
                    animation: scroll 2s infinite;
                }
            }
        }*/
}





.top-page {

    &.top-page-space {
        padding-top: 25px;
    }
}

.home-icon {
    font-size: 80px;
    line-height: 80px;
    color: $theme-alt-color;
}

// Ajax load
.floatingCirclesG{
    position:relative;
    width:74px;
    height:74px;
    margin:auto;
    transform:scale(0.6);
}

.f_circleG {
    position:absolute;
    background-color:rgb(255,255,255);
    height:13px;
    width:13px;
    border-radius:7px;
    animation-name: f_fadeG;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

.f_circleG.frotateG_01{
    left:0;
    top:30px;
    animation-delay:0.45s;
}

.f_circleG.frotateG_02{
    left:9px;
    top:9px;
    animation-delay:0.6s;
}

.f_circleG.frotateG_03{
    left:30px;
    top:0;
    animation-delay:0.75s;
}

.f_circleG.frotateG_04{
    right:9px;
    top:9px;
    animation-delay:0.9s;
}

.f_circleG.frotateG_05{
    right:0;
    top:30px;
    animation-delay:1.05s;
}

.f_circleG.frotateG_06{
    right:9px;
    bottom:9px;
    animation-delay:1.2s;
}

.f_circleG.frotateG_07{
    left:30px;
    bottom:0;
    animation-delay:1.35s;
}

.f_circleG.frotateG_08{
    left:9px;
    bottom:9px;
    animation-delay:1.5s;
}

@keyframes f_fadeG{
    0%{
        background-color:rgb(0,0,0);
    }

    100%{
        background-color:rgb(255,255,255);
    }
}

.content-main {
    padding-top: 50px;
    padding-bottom: 30px;

    &.home {

    }

    &.page {

    }

}




@media screen and (max-width: $screen-xxl-max) {
}

@media screen and (max-width: $screen-xl-max) {
}

@media screen and (max-width: $screen-lg-max) {
}

@media screen and (max-width: $screen-md-max) {
    body {
        font-size: 15px;
    }
}

@media screen and (max-width: $screen-sm-max) {
    body {
        font-size: 14px;
    }
}

@media screen and (max-width: $screen-xs-max) {
    .content-main-right{
        margin-top: 50px;
    }
    .content-main {
        padding-top: 30px;
        padding-bottom: 15px;
        &.home {
        }
        &.page {
        }
    }
}

/*
-------------------------------------------------------------------
- Rootline
  -------------------------------------------------------------------
 */

main{
    ul#rootline {
        padding: 0;
        margin: 0;
        height: 29px;
        margin-bottom: 30px;
        li{
            font-size: 12px;
            color: $gray-color1;
            letter-spacing: 0;
            line-height: 29px;
            display: inline-block;
            padding-right: 20px;
            position: relative;
            font-family: $font-family-nexa-bold;
            text-transform: uppercase;
            margin: 0;
            padding-left: 0;
            position: relative;
            a {

                color: $theme-base-color;

                &:hover {
                    text-decoration: none;
                }
            }
            &:before {
                content: '';
                position: absolute;
                left: inherit;
                right: 8px;
                top: 6px;
                width: 1px;
                height: 13px;
                background-color: $gray-color1;
            }
            &.last {
                padding-right: 0;
                a{
                    color: $gray-color2;
                }

                &:before {
                    display: none;
                }
            }
        }
    }

}


@media screen and (max-width: $screen-sm-max) {

}

@media screen and (max-width: $screen-xs-max) {
    main{
        ul#rootline {
            padding: 0;
            margin: 0;
            height: 29px;
            li {
                font-size: 11px;
                padding-right: 16px;
                letter-spacing: 1px;
                a {
                }
                &:before {
                    right: 6px;
                }
            }
        }

    }
}
