//@import url("//hello.myfonts.net/count/31b2e3");
/*
@font-face {
    font-family: 'GaspoSlab-Bold';
    src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_0_0.eot');
    src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_0_0.eot?#iefix') format('embedded-opentype'),
         url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_0_0.woff2') format('woff2'),
         url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_0_0.woff') format('woff'),
         url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_0_0.ttf') format('truetype');
}

@font-face {
    font-family: 'GaspoSlab-Black';
    src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_1_0.eot');
    src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_1_0.eot?#iefix') format('embedded-opentype'),
         url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_1_0.woff2') format('woff2'),
         url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_1_0.woff') format('woff'),
         url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_1_0.ttf') format('truetype');
}

@font-face {
    font-family: 'GaspoSlab-Light';
    src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_2_0.eot');
    src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_2_0.eot?#iefix') format('embedded-opentype'),
         url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_2_0.woff2') format('woff2'),
         url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_2_0.woff') format('woff'),
         url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B2E3_2_0.ttf') format('truetype');
}

//@import url("//hello.myfonts.net/count/31b249");

@font-face {
    font-family: 'BrandonTextWeb-Bold';
    src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B249_0_0.eot');
    src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B249_0_0.eot?#iefix') format('embedded-opentype'),
         url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B249_0_0.woff2') format('woff2'),
         url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B249_0_0.woff') format('woff'),
         url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/31B249_0_0.ttf') format('truetype');
}
*/