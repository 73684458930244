/*
-------------------------------------------------------------------
- MIXIN :: Font awesome icons
-------------------------------------------------------------------
*/
@mixin icon($icon, $margin:0) {
    @include icon-font-awesome();
    content: $icon;
    margin: $margin;
}

@mixin icon-font-awesome() {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin link-color($color: $theme-base-color, $hover-color: $link-hover-color, $style: none) {
    
    a,
    a:focus,
    a:active {
        color: $color;
        text-decoration: none;
        
        &:hover {
            color: $hover-color;
            text-decoration: $style;
        }
    }
}

@mixin this-link-color($color: $theme-base-color, $hover-color: $link-hover-color, $style: none) {
    color: $color;
    
    &:hover {
        color: $link-hover-color;
        text-decoration: $style;
    }
    
    &:focus,
    &:active {
        color: $color;
        text-decoration: none;
        
        &:hover {
            color: $hover-color;
            text-decoration: $style;
        }
    }
}

@mixin link-static-color($color: $theme-base-color, $style: none) {
    
    a,
    a:focus,
    a:active {
        color: $color;
        
        &:hover {
            color: $color;
            text-decoration: $style;
        }
    }
}


/*
-------------------------------------------------------------------
- MIXIN :: Buttons
-------------------------------------------------------------------
*/
@mixin button-variant-2($color, $background, $border, $color2, $background2, $border2) {
	display: inline-block;
	color: $color;
	background-color: $background;
	border-color: $border;
	
	&:hover,
	&:focus,
	&:active,
	&.active,
	.open .dropdown-toggle & {
		color: $color2;
		background-color: $background2;
		border-color: $border2;
	}
	&:active,
	&.active,
	.open .dropdown-toggle & {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: $background;
			border-color: $border;
		}
	}
	
	.badge {
		color: $background;
		background-color: #fff;
	}
}

/*
-------------------------------------------------------------------
- MIXIN :: Hover darken colors
-------------------------------------------------------------------
*/
@mixin button-variant-default($color, $background, $border) {
	display: inline-block;
	color: $color;
	background-color: $background;
	border-color: $border;

    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
	
	&:hover,
	&:focus,
	&:active,
	&.active,
	.open .dropdown-toggle & {
		color: $color;
		background-color: darken($background, 11%);
		border-color: darken($border, 11%);
	}
	&:active,
	&.active,
	.open .dropdown-toggle & {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: $background;
			border-color: $border;
		}
	}
	
	.badge {
		color: $background;
		background-color: #fff;
	}
}

/*
-------------------------------------------------------------------
- MIXIN :: Hover darken colors
-------------------------------------------------------------------
*/
@mixin button-variant-default-no-bg($color, $background, $border) {
	display: inline-block;
	color: $color;
	background-color: $background;
	border-color: $border;
	
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
	
	&:hover,
	&:focus,
	&:active,
	&.active,
	.open .dropdown-toggle & {
		color: darken($color, 11%);
		background-color: $background;
		border-color: darken($border, 11%);
	}
	&:active,
	&.active,
	.open .dropdown-toggle & {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: $background;
			border-color: $border;
		}
	}
	
	.badge {
		color: $background;
		background-color: #fff;
	}
}