
.offcanvas {
    display: none;
    position: fixed;
    z-index: 100000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.60);

    &.offcanvas-mobile-menu {
        padding-top: 70px;
        z-index: 110000;
        background: $white-color;
        overflow: hidden;
        -webkit-overflow-scrolling: auto;
    }

    .offcanvas-content-wrap {
        position: relative;
        z-index: 100;
        margin: 20px;
        background: $white-color;
    }

    .offcanvas-content {
        padding: 25px;
    }

    &.offcanvas-map {
        height: 100%;

        .offcanvas-content-wrap {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .offcanvas-content {
            height: 100%;
            overflow: scroll;
        }
    }

    .offcanvas-content-top {
        text-align: right;

        @include link-color($black-color, $link-hover-color);

        .offcanvas-close {

            .offcanvas-close-text {
                display: inline-block;
                vertical-align: top;
                padding-top: 6px;
                padding-right: 10px;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
            }

            .offcanvas-close-icon {
                display: inline-block;
                vertical-align: top;
                font-size: 20px;
            }
        }
    }

    .offcanvas-content-navigation {

        .map-navigation-controls {
            display: inline-table;

            .map-navigation-control-wrap {
                display: table-cell;
                vertical-align: top;
            }

            .map-navigation-control {
                display: block;
                margin: 0 2px 10px;
                border-radius: 50%;
                background: #D8D8D8;
                transition: all 0.5s ease-in-out;

                &.active {
                    background: $theme-base-color;
                }

                &.map-navigation-control-1 {
                    margin-top: 18px;
                    width: 33px;
                    height: 33px;
                }

                &.map-navigation-control-2 {
                    margin-top: 17px;
                    width: 45px;
                    height: 45px;
                }

                &.map-navigation-control-3 {
                    margin-top: 24px;
                    width: 36px;
                    height: 36px;
                }

                &.map-navigation-control-4 {
                    margin-top: 16px;
                    width: 31px;
                    height: 31px;
                }

                &.map-navigation-control-5 {
                    width: 68px;
                    height: 68px;
                }
            }
        }
    }

    .offcanvas-content-inner {

    }

    /* Menu mobile layout */
    .mobile-menu-top {
        position: absolute;
        display: table;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 70px;
    }

    .mobile-menu-top-item {
        display: table-cell;
        vertical-align: middle;
        text-align: right;

        &.mobile-menu-wrap-hamburger{
            width: 100px;
        }
        .hamburger {
            margin-top: 5px;
            margin-right: 0;
        }
    }

    .mobile-menu {
        height: 100%;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .mobile-menu-items {
        padding-bottom: 15px;
        @include link-color($theme-base-color, $theme-base-color);
    }

    .mobile-menu-item {
        display: block;
        position: relative;
        padding: 0 10px;
        line-height: 54px;
        height: 54px;
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: center;
        font-family: $font-family-nexa-regular;
        &.open {


        }

        /*
        &:before {
            display: inline-block;
            position: absolute;
            left: 0;
            right: 0;
            content: ' ';
            margin: 0 auto;
            bottom: -45px;
            text-align: center;
            width: 20px;
            border-top: 30px solid $white-color;
            border-right: 30px solid transparent;
            border-bottom: 30px solid transparent;
            border-left: 30px solid transparent;
        }
        */

        &:before {
            display: inline-block;
            position: absolute;
            left: 0;
            right: 0;
            content: ' ';
            margin: 0 auto;
            bottom: -12px;
            text-align: center;
            width: 6px;
            border-top: 6px solid $white-color;
            border-right: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 6px solid transparent;
        }

        &.active {
            color: $theme-base-color;
        }
    }

    .mobile-menu-subitems {
        //padding-top: 15px;
        //padding-bottom: 15px;
        @include link-color($white-color, $white-color);


        &#mobile-menu-subitems-id-2{
            background-color: $theme-color-1;
        }
        &#mobile-menu-subitems-id-3{
            background-color: $theme-color-1;
        }
        &#mobile-menu-subitems-id-4{
            background-color:  $theme-color-1;
        }
        &#mobile-menu-subitems-id-5{
            background-color:  $theme-color-1;
        }
    }

    .mobile-menu-subitem {
        display: block;
        padding: 0 10px 0 10px;
        font-size: 17px;
        letter-spacing: 1px;
        text-transform: none;
        text-align: center;
        height: 50px;
        line-height: 50px;
        //background: $theme-base-color;
        color: $white-color;
        font-family: $font-family-nexa-regular;
        border-bottom: 1px solid rgba(255,255,255,0.15);

        &.active {
            //color: $theme-base-color;
            background: rgba(20, 18, 18, 0.17);
        }
    }
}


@media screen and (max-width: $screen-xxl-max) {
}

@media screen and (max-width: $screen-xl-max) {
}

@media screen and (max-width: $screen-lg-max) {
}

@media screen and (max-width: $screen-md-max) {
}

@media screen and (max-width: $screen-sm-max) {
}

@media screen and (max-width: $screen-xs-max) {
}