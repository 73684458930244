#mfPreviewBar{
    display: none;
}
.map-canvas-content.canvas-content{
    max-height: 90vh;
}
/*
#uid1{
    .spotsocial{
        .aggregator{
            .items{
                .item{
                    width: 25%;
                }
            }
        }
    }
}
*/
main{
    font-size: 1em;
    line-height: 1.740em;
    letter-spacing: 0;
    p {
        margin: 0 0 0 0;
        padding: 0 0 1.222em 0;

    }
    ul {
        list-style: none;
        padding-top: 0;
        padding-left: 20px;
        margin-top: 0;
        margin-bottom: 1.222em;
        li {
            position: relative;
            margin: 10px 0;
            padding-left: 24px;
            &:before {
                position: absolute;
                left: 2px;
                top: 14px;
                content: "•";
                color: $theme-base-color;
                font-size: 22px;
                line-height: 0;
            }
        }
    }
    strong{
        font-family: $font-family-nexa-bold;
        font-weight: 700;
    }
    p+ul{
        margin-top: -0.7em;
    }
    .gap-top-bottom{
        //margin-bottom: 1.35em;
        //margin-top: 1.35em;
        margin-bottom: 1.95em;
        margin-top: 1.95em;
    }
    .gap-top{
        //margin-top: 1.35em;
        margin-top: 1.95em;
    }
    .gap-bottom{
        //margin-bottom:  1.35em;
        margin-bottom: 1.95em;
    }

    .content-main-right{
        .gap-top-bottom{
            margin-bottom: 2.95em;
            margin-top: 2.95em;
        }
        .gap-top{
            margin-top: 2.95em;
        }
        .gap-bottom{
            margin-bottom: 2.95em;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: normal;
    }
    h1 {
        font-family: $font-family-nexa-bold;
        font-size: 40px;
        line-height: 40px;
        color: $theme-base-color;
        margin: 0 0 1em;
        letter-spacing: 0;
    }
    h2 {
        font-family: $font-family-nexa-regular;
        font-size: 23px;
        line-height: 32px;
        color: $theme-mono-color;
        margin: 0 0 1em;
        letter-spacing: 0;
    }
    h3 {
        font-family: $font-family-nexa-regular;
        font-size: 20px;
        text-transform: none;
        color: $theme-base-color;
        margin: 3px 0 1em;
        letter-spacing: 0;
    }
    h4 {
    }

    h5 {
    }

    h6 {
    }

    /*
    .content-main-left{
        a:not(.btn){
            color:$black-color;
            font-style: italic;
            &:hover{
                color:$theme-alt-color;
            }
        }
    }
    */

    a {
        transition: all 0.5s ease;
        /*&.external-link-new-window {
            @include cfi-margin($cfi-content-external-link-new-window);
            @include cfi-size(14px);
        }
        &.internal-link {
            @include cfi-margin($cfi-content-internal-link);
            @include cfi-size(14px);
        }*/
        a,
        a:link,
        a:focus,
        a:active {
            color:  $theme-alt-color;
            text-decoration: none;
            outline: none;
            outline-style: none;
        }
        a:hover{
            text-decoration: underline;
        }

        &.download {
            @include cfi-margin($cfi-content-download, 26px);
            @include cfi-size(25px);
            @include cfi-style(vertical-align, text-bottom);
        }
    }
}

hr {
    width: 100%;
    border-top: 1px solid rgba($theme-alt-color, 0.30);
    padding: 0 0 15px;
    margin: 30px 0 0;
}




@media screen and (max-width: $screen-lg-max) {
}

@media screen and (max-width: $screen-md-max) {
    main{
        h1 {
            font-size: 36px;
            line-height: 36px;
        }
        h2 {
            font-size: 21px;
            line-height: 21px;
        }
        h3 {
            font-size: 19px;
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    main{

    }
}

@media screen and (max-width: $screen-xs-max) {
    main{

    }
}


/* ------------------------------------------------
*   FCE GENERAL RULES
* ------------------------------------------------ */

.fce {
    .fce-grid-border{
        border: 1px solid #dcdcdc;
        padding: 25px 25px 10px 25px;
    }
}

@media screen and (max-width: $screen-xs-max) {
    .fce {
        .fce-grid-border{
            padding: 15px 15px 0px 15px;
        }
    }
}



/* ------------------------------------------------
*   FCE TEXT
* ------------------------------------------------ */

.fce {
    //margin-bottom: 35px;

    &.fce-text {
        margin-bottom: 2em;
        .fce-header {
            h1 {
                //margin-top: 50px;
            }
            h2 {
                //margin-top: 40px;
            }
            h3 {
                //margin-top: 20px;
            }
        }
    }
}

@media screen and (max-width: $screen-md-max){
    .fce {
        &.fce-text {
            .fce-header {
                h1 {
                    //margin-top: 40px;
                }
                h2 {
                    //margin-top: 30px;
                }
                h3 {
                    //margin-top: 15px;
                }
            }
            ul {
                li {
                    &:before {
                    }
                }
            }
        }
    }
}
@media screen and (max-width: $screen-sm-max) {
    .fce{
        &.fce-text {
            .fce-header {
                h1 {
                    //margin-top: 30px;
                }
                h2 {
                   // margin-top: 20px;
                }
                h3 {
                    //margin-top: 10px;
                }
            }
        }
    }
}

/* ------------------------------------------------
*   FCE SPACER
* ------------------------------------------------ */
.fce {
    &.fce-spacer {
        margin-bottom: 0px;

        .fce-spacer-h1 {
            font-family:$font-family-nexa-bold ;
            font-size: 40px;
            line-height: 58px;
            color: transparent;
            margin: 10px 0 15px;
        }

        .fce-spacer-h2 {
            font-family: $font-family-nexa-regular ;
            font-size: 28px;
            line-height: 39px;
            color: transparent;
            margin: 5px 0 10px;
        }
    }

}

/* ------------------------------------------------
*   FCE IMAGE
* ------------------------------------------------ */

.fce {
    &.fce-image {
        margin-bottom: 1.222em;
        figcaption{
            padding-top: 6px;
            font-size: 0.85em;
            line-height: 1.35em;
            color: #858585;
        }
        &.image-align-left {
            text-align: left;
        }
        &.image-align-middle {
            text-align: center;
        }
        &.image-align-right {
            text-align: right;
        }
    }
}

/* ------------------------------------------------
*   FCE SEPARATOR
* ------------------------------------------------ */
.fce{
    &.fce-separator {
        margin-bottom: 1.222em;
        .fce-separator-element {
            width: 100%;
            border-top: 1px solid rgba($theme-alt-color, 0.30);
            padding: 0 0 15px;
            margin: 15px 0 0;
        }
    }

}


/* ------------------------------------------------
*   FCE HIGHLIGHT TEXT
* ------------------------------------------------ */

.fce{
    &.fce-highlight-text {
        line-height: 1em;
        padding-bottom: 30px;
        a{
            display: block;
            text-decoration: none;
            transition:1s all ease;
            &:hover{
                text-decoration: none;
            }
        }


        .fce-highlight-text-wrap {
            position: relative;
            //smargin: 30px 0;

            //border-top: 2px solid $black-color;
            //border-bottom: 2px solid $black-color;
            background-color:rgba($theme-alt-color,0.1);
            border-radius: 3px;
            padding: 2em 1em;
            text-align: left;
            display: block;
            position: relative;
            //&:after,
            &:before {
                display: none;
                position: absolute;
                content: ' ';
                left: 0;
                top:-17px;
                width: 34px;
                height: 34px;
                background-color: $white-color;
                background-repeat: no-repeat;
            }
            .btn{
                position: absolute;
                bottom:2em;
                left:1em;
            }
            /*
            &.fce-highlight-color-1{
                border-top: 2px solid $theme-color-1;
                border-bottom: 2px solid $theme-color-1;

                &:before {
                    display: block;
                    background-image: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Images/Layout/testapsd-blue.png');
                }
            }
            &.fce-highlight-color-2{
                border-top: 2px solid $theme-color-2;
                border-bottom: 2px solid $theme-color-2;
                &:before {
                    display: block;
                    background-image: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Images/Layout/testapsd-green.png');
                }
            }
            &.fce-highlight-color-3{
                border-top: 2px solid $theme-color-3;
                border-bottom: 2px solid $theme-color-3;
                &:before {
                    display: block;
                    background-image: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Images/Layout/testapsd-yellow.png');
                }
            }
            */
            /*&:after {
                bottom: -15px;
            }*/

            p {
                margin: 0;
                padding: 0;
            }
            .fce-highlight-title {
                font-size: 32px;
                line-height: 38px;
                margin-top: 10px;
                margin-bottom: 13px;
                font-weight: 300;
                display: block;
                color:$theme-alt-color;
            }
            .fce-highlight-subtitle{
                font-size: 15px;
                line-height: 30px;
                font-weight: 700;
                color:$theme-alt-color;
                margin-bottom: 6px;
            }
            /*
            &.fce-highlight-color-1{
                .fce-highlight-title {
                    color: $theme-color-1
                }
            }
            &.fce-highlight-color-2{
                .fce-highlight-title {
                    color: $theme-color-2
                }
            }
            &.fce-highlight-color-3{
                .fce-highlight-title {
                    color: $theme-color-3
                }
            }
            */
            .fce-highlight-text {
                font-size: 1em;
                font-family: $font-family-nexa-regular;
                line-height: 28px;
                color: $gray-color1;
                display: block;
            }
        }
    }
}

@media screen and (max-width: $screen-lg-max) {
}
@media screen and (max-width: $screen-md-max) {
    .fce{
        &.fce-highlight-text {

            .fce-highlight-text-wrap {


                .fce-highlight-title {
                    font-size: 30px;
                    line-height: 32px;
                }
            }
        }
    }
}
@media screen and (max-width: $screen-sm-max) {
    .fce {
        &.fce-highlight-text {

            .fce-highlight-text-wrap {

                .fce-highlight-title {
                    font-size: 24px;
                    line-height: 26px;
                }

            }
        }
    }
}
@media screen and (max-width: $screen-xs-max) {
}

/* ------------------------------------------------
*   FCE QUOTED TEXT
* ------------------------------------------------ */

.fce {
    &.fce-quoted-text {
        text-align: center;
        margin-bottom: 1.222em;
        position: relative;
        .fce-quoted-text-wrap {
            &:before {
                width: 100%;
                text-align: center;
                content: '“';
                position: absolute;
                top: 10px;
                left: 0;
                font-size: 100px;
                line-height: 1em;
            }
            &:after {
                width: 100%;
                text-align: center;
                content: '”';
                position: absolute;
                bottom: -55px;
                left: 0;
                font-size: 100px;
                line-height: 1em;
            }

            display: inline-block;
            margin: 55px 0;
            //border-left: 3px solid $black-color;
            //padding-left: 25px;
            font-family: $font-family-nexa-light;
            font-size: 28px;
            line-height: 44px;
            //text-align: left;
            color: $theme-base-color;
            @include hyphens();
            padding-left: 8%;
            padding-right: 8%;
            p {
                margin: 0;
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: $screen-lg-max) {
}
@media screen and (max-width: $screen-md-max) {
    .fce{
        &.fce-quoted-text {
            .fce-quoted-text-wrap {
                margin: 50px 0;
                font-size: 24px;
                line-height: 38px;
                padding-left: 4%;
                padding-right: 4%;
                &:before {
                    top: 10px;
                    font-size: 80px;
                }
                &:after {
                    bottom: -45px;
                    font-size: 80px;
                }
            }
        }
    }
}
@media screen and (max-width: $screen-sm-max) {

}
@media screen and (max-width: $screen-xs-max) {
    .fce{
        &.fce-quoted-text {
            .fce-quoted-text-wrap {
                margin: 45px 0;
                font-size: 20px;
                line-height: 32px;
                padding-left: 2%;
                padding-right: 2%;
                &:before {
                    top: 10px;
                    font-size: 70px;
                }
                &:after {
                    bottom: -35px;
                    font-size: 70px;
                }
            }
        }
    }
}

/* ------------------------------------------------
*   FCE GALLERY
* ------------------------------------------------ */

.fce {
    &.fce-gallery{
        margin-bottom: 1.222em;
        .owl-theme {
            overflow: hidden;
            width: 100%;

            min-height: 150px;
            // Styling Next and Prev buttons

            .owl-item{
                float:left;
            }
            .owl-nav {
                position: absolute;
                margin-top: -30px;
                top: 50%;
                width: 100%;

                text-align: center;
                -webkit-tap-highlight-color: transparent;

                svg{
                    fill: $white-color;
                    use,
                    path{
                        fill: $white-color;
                    }
                }
                [class*='owl-'] {
                    position: absolute;
                    background-color: rgba(255, 255, 255, 0.40);
                    width: 60px;
                    height: 60px;
                    margin: 0;
                    padding-top: 18px;
                    /*
                    color: $nav-color;
                    font-size: $nav-font-size;
                    margin: $nav-margin;
                    padding: $nav-padding;
                    background: $nav-background;
                    display: inline-block;
                    cursor: pointer;
                    */
                    border-radius: 0px;
                    transition: all 1.5s ease;

                    &:hover {
                        background-color: $white-color;
                        /*
                        background: $nav-background-hover;
                        color:$nav-color-hover;
                        text-decoration: none;
                        */
                        svg{
                            fill: $theme-base-color;
                            use,
                            path{
                                fill: $theme-base-color;
                            }
                        }
                    }
                }
                .owl-prev{
                    //left: 10px;
                    left:0;
                    padding-right: 10px;
                }
                .owl-next{
                    //right: 10px;
                    right: 0;
                    padding-left: 10px;
                }
                .disabled {
                   // opacity: $nav-disabled-opacity;
                   // cursor: default;
                }
            }

            // Styling dots
            .owl-nav.disabled + .owl-dots {
                margin-top: 10px;
            }

            .owl-dots {
                position: absolute;
                bottom: 0;
                width: 100%;
                .owl-dot {
                    span {
                        width: 12px;
                        height: 12px;
                        background: transparent;
                        border: 1px solid #C8C8C8;
                        border-radius: 12px;
                        -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow:    0px 0px 4px 0px rgba(0, 0, 0, 0.15);
                        box-shadow:         0px 0px 4px 0px rgba(0, 0, 0, 0.15);
                    }

                    &.active,
                    &:hover {
                        span {
                            background: $white-color;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: $screen-xxl-max) {
}
@media screen and (max-width: $screen-xl-max) {
}
@media screen and (max-width: $screen-lg-max) {
}
@media screen and (max-width: $screen-md-max) {
.fce {
    ///margin-bottom: 25px;
    }
}
@media screen and (max-width: $screen-sm-max) {
.fce {
    //margin-bottom: 20px;
    }
}


/* ------------------------------------------------
*   FCE LOGOS
* ------------------------------------------------ */

.fce{
    .fce-logos{
        width: 100%;
        text-align: center;
        clear: both;
        margin-bottom: 1.222em;

        &.fce-logos-left{
            text-align: left;
        }
        &.fce-logos-right{
            text-align: right;
        }

        .fce-logo{
            width: 250px;
            display: inline-block;
            text-align: center;
            .fce-logo-wrap{
                display: table;


                >a{
                    display: table-cell;
                    display: inline-block;
                }
            }
        }
    }
}


/* ------------------------------------------------
*   FCE => CONTENT RIGHT
* ------------------------------------------------ */

.content-main-right {
    .fce {
        &.fce-quoted-text {
            color: #999;
            .fce-quoted-text-wrap {
                margin: 15px 0 25px;
                border-left: 2px solid #CCC;
                padding-left: 15px;
                font-size: 22px;
                line-height: 30px;
                font-family: $font-family-nexa-light;
            }
        }
    }
}

/* ------------------------------------------------
*   GOOGLE MAP
* ------------------------------------------------ */

.map{
    .map-canvas-content{
        width: 100%;
        height: 400px;
        margin-bottom: 40px;
        margin-top: 40px;
        .canvas-content{
            width: 100%;
            height: 400px;
        }
    }
    .gm-style-iw{
        left: 11px !important;
        top: 11px !important;
        overflow: hidden;

        //width: 300px;
        //height: 200px;
        .logo{
            float: left;
            width: 250px;
            text-align: center;
            margin-bottom: 20px;
            img{
                //width: 150px;
                //height: 94px;
                height: 60px;
                display: inline-block;
                margin-bottom: 2px;
                margin-top: 10px;
            }
        }
        .cnt{
            float: left;
            text-align: center;
            width: 250px;

            //padding: 15px;
            // @include cff-Niveau();
            //color: $color1;
            overflow: hidden;
            clear: both;
            font-size: 15px;
            a{
                //@include cff-Niveau(Bold);
                //color: $color1;
            }
            .name{
                //@include cff-Niveau(Bold);
                text-transform: uppercase;
                margin-bottom: 5px;
                display: none;
            }
            .teaser{
                margin-bottom: 5px;
            }
            .route{
                padding-top: 20px;
                padding-bottom: 40px;
            }

            .button-theme {
                font-size: 13px;
                letter-spacing: 0px;
                line-height: 15px;
                text-transform: uppercase;
                padding: 7px 15px;
                background-color: transparent;
                color: $theme-base-color;
                border: $theme-base-color solid 1px;
                letter-spacing: 2px;
                border-radius:  15px;
                text-decoration: none;
                &:hover{
                    background-color: $theme-base-color;
                    color: $white-color;
                }
            }
        }
    }
}
