

.news{
    .news-list-view{

        a{
            text-decoration: none;
        }

        .news-link{
            display: block;
            margin-bottom: 30px;
            span{
                display: block;
                width: 100%;
            }
        }
        .news-list-row{
            height: 1px;
            width: 100%;
            display: inline-block;
        }
        .news-list-category{
            font-size: 13px;
            color: $theme-base-color;
            font-family: $font-family-nexa-regular;
            text-transform: uppercase;
            width: 100%;
            letter-spacing: 3px;
            display: block;
            margin-bottom: 8px;
        }
        .header-news{
            h3{
                margin: 0;
                padding: 0;
                line-height:1.15em;
                color: $black-color;
                font-size: 26px;
                font-family: $font-family-nexa-regular;
                letter-spacing: 1px;
                text-transform: none;
                font-weight: normal;
            }
        }
        .news-list-date{
            font-family: $font-family-nexa-regular;
            color: $theme-mono-color;
            margin-bottom: 10px;
            font-size: 0.9em;
        }
        .news-img-wrap{
            margin-bottom: 20px;
        }
        .teaser-text{
            color: $black-color;
            margin-top:10px;
        }

        .news-row-above{
            .news-link {
                border-top: 3px dashed $theme-base-color;
                padding-top: 20px;
            }
        }
    }


    &.news-single{
        .news-list-date{
            font-family: $font-family-nexa-regular;
            color: $theme-mono-color;
            margin-bottom: 10px;
            font-size: 0.9em;
        }
        .teaser-text{
            font-family: $font-family-nexa-bold;
            font-weight: 700;
        }
        h2{
            margin: 0;
            padding: 0;
            line-height:1.15em;
            color: $black-color;
            font-size: 26px;
            font-family: $font-family-nexa-regular;
            letter-spacing: 1px;
            text-transform: none;
            font-weight: normal;
        }
        .news-list-category{
            font-size: 13px;
            color: $theme-base-color;
            font-family: $font-family-nexa-regular;
            text-transform: uppercase;
            width: 100%;
            letter-spacing: 3px;
            display: block;
            margin-bottom: 8px;
        }
        .news-img-wrap{
            margin-bottom: 20px;
        }
    }
}

.page-navigation{
    border-top: 1px solid $gray-color3;
    border-bottom: 1px solid $gray-color3;
    float: left;
    width: 100%;
    margin-bottom: 2em;
    &>p{
        text-align: center;
        display: block;
        width: 100%;
        float: left;
        font-size: 0.8em;
        padding-bottom: 0.8em;
    }
    &>ul{
        text-align: center;
        display: block;
        width: 100%;
        float: left;
        list-style: none;
        background-color: lighten($gray-color3,3);
        margin-bottom: 0;
        li{
            list-style: none;
            display: inline-block;
            &:before{
                display: none;
            }
        }        
    }
}

/*


.content-main {


    .news {

        &.news-single {

            .news-detail-title {
                padding-top: 5px;
                color: $theme-base-color;
                //font-family: $font-family-brandon-bold;
                font-size: 24px;
                line-height: 29px;
            }
        }

        .news-list-view {

            .news-content-wrap {

            }

            .news-media-wrap {
                display: none;
                margin-bottom: 15px;
            }

            .news-list-date {
                color: $text-color;
            }

            .news-list-title {
                color: $theme-base-color;
            }

            .news-list-teaser {
                color: $text-color;
            }
        }
    }

    .content-main-left {

        .news {

            &.news-single {

                .news-media-wrap {
                    display: none;
                }

                .news-content-wrap {

                }
            }

            .news-list-view {

                .news-content-wrap {
                    margin-bottom: 25px;
                    padding-bottom: 25px;
                    border-bottom: 1px solid rgba(#979797, 0.24);
                }

                .news-list-title {
                    padding-top: 5px;
                    //color: $theme-alt-color;
                    color: $theme-base-color;
                    //font-family: $font-family-brandon-bold;
                    font-size: 18px;
                    line-height: 25px;

                    a {
                        //cursor: default;
                        //pointer-events: none;
                        //font-weight: normal;
                    }
                }

                .news-list-teaser {

                    p {
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    .content-main-right {

        .news {

            &.news-single {

                .news-media-wrap {
                    @include img-responsive();
                }

                .news-content-wrap {
                    display: none;
                }

                .news-detail-title {

                }

                .news-detail-text {

                }
            }
        }
    }
}


.content-news {
    padding-top: 45px;
    padding-bottom: 70px;

    .news {

        .news-list-view {
            @include make-row();

            .article {
                @include make-sm-column(4);
            }

            .news-wrap {
                margin-bottom: 25px;
                padding: 60px 35px 35px 35px;
                border: 1px solid rgba(#979797, 0.24);
            }

            .news-list-image {
                display: none;
            }

            .news-list-date {
                //color: $theme-alt-color;
                color: $text-color;
                //////font-family: $font-family-gaspo-bold;
                font-size: 28px;
                line-height: 41px;
            }

            .news-list-title {
                color: $theme-base-color;
                //////font-family: $font-family-brandon-bold;
                font-size: 25px;
                line-height: 30px;
                //font-weight: bold;

                a {
                    font-weight: normal;
                }
            }

            .news-list-author {
                padding-bottom: 15px;
                font-size: 12px;
                letter-spacing: 2.5px;
                line-height: 35px;
            }

            .news-list-teaser {

            }
        }
    }
}

.content-main-news-wrap {
    background: #F7F7F7;
}



.content-main-news {
    padding-top: 100px;
    padding-bottom: 75px;

    .news {

        .news-list-view {
            @include make-row();

            .article {
                @include make-sm-column(6);

                &.hasMedia {
                    @include make-sm-column(12);

                    .news-wrap {
                        display: table;
                        table-layout: fixed;
                        width: 100%;
                    }

                    .news-media-wrap {
                        display: table-cell;
                        vertical-align: top;
                        width: 50%;
                        overflow: hidden;
                    }

                    .news-content-wrap {
                        display: table-cell;
                        vertical-align: top;
                        //width: ;
                    }
                }
            }

            .news-wrap {
                position: relative;
                margin-bottom: 35px;
                background: $white-color;
            }

            .news-media-wrap {
            }

            .news-content-wrap {
                padding: 60px 35px 35px 35px;
            }

            .news-list-image {
                text-align: right;

                > a {

                }

                .img-responsive {
                    max-width: none;
                    width: 100%;
                }
            }

            .news-list-date {
                font-size: 12px;
                letter-spacing: 2.5px;
                line-height: 25px;
            }

            .news-list-title {
                //color: $theme-base-color;
                //font-family: $font-family-brandon-bold;
                font-size: 25px;
                line-height: 30px;
            }

            .news-list-author {
                display: none;
            }

            .news-list-teaser {

            }
        }
    }
}

*/