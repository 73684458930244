$spotsocial-facebook-color:         #3b5998;
$spotsocial-twitter-color:          #55acee;
$spotsocial-youtube-color:          #ba0911;
$spotsocial-googleplus-color:       #ad4c3c;
$spotsocial-instagram-color:        #3f729b;
$spotsocial-tumblr-color:           #35465C;
$spotsocial-pinterest-color:        #c72527;
$spotsocial-green-color:            #72d066;
$spotsocial-item-margin:            15px;

.spotsocial {

    .aggregator {

        .aggregator-single {

        }

        .aggregator-multiple {
            padding: 10px 0 0 0;
        }

        .aggregator-toolbar {
            padding: 0 0 60px 0;

            text-align: center;
        }

        .aggregator-container {
            position: relative;
            z-index: 1;
            margin: 0 -$spotsocial-item-margin;
        }

        .filters {
            display: inline-table;
            width: auto;

            .filter {
                display: table-cell;
                vertical-align: middle;
                padding: 0 4px;

                &.filter-any {

                    a {
                    }
                }

                &.filter-facebook-post,
                &.filter-facebook-event {

                    a.active {
                        background-color: $spotsocial-facebook-color;
                    }
                }

                &.filter-twitter-post {

                    a.active {
                        background-color: $spotsocial-twitter-color;
                    }
                }

                &.filter-google-post {

                    a.active {
                        background-color: $spotsocial-googleplus-color;
                    }
                }

                &.filter-google-video {

                    a.active {
                        background-color: $spotsocial-youtube-color;
                    }
                }

                &.filter-instagram-image {

                    a.active {
                        background-color: $spotsocial-instagram-color;
                    }
                }

                &.filter-tumblr-post {

                    a.active {
                        background-color: $spotsocial-tumblr-color;
                    }
                }

                &.filter-pinterest-pint {

                    a.active {
                        background-color: $spotsocial-pinterest-color;
                    }
                }


                //News
                &.filter-news {
                    a.active {
                        background-color: $theme-alt-color;
                    }
                }

                //News
                &.filter-newspres {
                    img{
                        width: 65px;
                    }
                    a.active {
                        background-color: $theme-alt-color;
                    }
                }

                a {
                    display: inline-block;
                    min-width: 65px;
                    line-height: 65px;
                    background-color: #dedede;
                    color: #ffffff;
                    text-decoration: none;
                    font-size: 17px;
                    letter-spacing: 2px;
                    font-weight: normal;
                    text-transform: uppercase;

                    &.active {
                        background-color: $spotsocial-green-color;
                    }
                }
            }
        }

        .items {

            .items-wrap {

            }

            .item {
                float: left;
                //width: 33.333333333333333333%;
                width: 25%;
                color: #333333;


                .provider {

                    .provider-icon {
                        width: 35px;
                        height: 35px;
                        display: block;
                        text-align: center;
                        border: 1px solid rgba(255, 255, 255, 0.39);
                    }
                }
                &.item-facebook {
                    .provider {
                        .provider-icon {
                            //border-color: rgba(255, 255, 255, 0.49);
                            background-color: $spotsocial-facebook-color;
                            padding-top: 4px;
                        }
                    }
                }

                &.item-twitter {
                    color: #ffffff;
                    a {
                        text-decoration: none;
                        color: #ffffff;
                    }
                    .item-wrap {
                        background-color: $spotsocial-twitter-color;
                    }
                    .provider {
                        .provider-icon {
                            //border-color: #fff;
                            padding-top: 4px;
                            background-color: $spotsocial-twitter-color;
                        }
                    }
                    .date {
                        .date-created {
                            color: #c6e5ef;

                        }
                    }

                    .counters {
                        color: #ffffff;
                    }

                    .profile {
                        .profile-name {
                            color: $theme-base-color;
                        }
                    }
                }

                &.item-google-post {

                    .provider {

                        .provider-icon {
                            border-color: $spotsocial-googleplus-color;

                            background-color: $spotsocial-googleplus-color;
                        }
                    }
                }

                &.item-google-video {
                    .provider {
                        .provider-icon {
                            //border-color: $spotsocial-youtube-color;
                            padding-top: 6px;
                            background-color: $spotsocial-youtube-color;
                        }
                    }
                    .picture {
                        padding-bottom: 56.25%;
                        position: relative;
                        display: block;
                        height: 0;
                        overflow: hidden;
                        .picture-wrap {
                            position: absolute;
                            top: -16.6%;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border: 0;
                            img{

                            }
                        }
                    }
                }
                &.item-instagram {
                    .provider {
                        .provider-icon {
                            border-color: $spotsocial-instagram-color;
                            background-color: $spotsocial-instagram-color;
                        }
                    }
                }
                &.item-tumblr {
                    .provider {
                        .provider-icon {
                            border-color: $spotsocial-tumblr-color;

                            background-color: $spotsocial-tumblr-color;
                        }
                    }
                }
                &.item-pinterest {
                    .provider {
                        .provider-icon {
                            border-color: $spotsocial-pinterest-color;
                            background-color: $spotsocial-pinterest-color;
                        }
                    }
                }


                //News
                &.item-news {
                    .provider {
                        .provider-icon {
                            //border-color: rgba(255, 255, 255, 0.49);
                            background-color: $theme-alt-color;
                            padding-top: 4px;
                        }
                    }
                    .item-body {
                        padding: 5px 15px 15px 15px;
                        .title{
                          //  font-family: $font-family-frutiger-bold;
                            margin-bottom: 5px;
                        }
                    }
                }

                //News
                &.item-newspres {
                    .provider {
                        .provider-icon {
                            //border-color: rgba(255, 255, 255, 0.49);
                            background-color: $theme-alt-color;
                            padding: 0;
                        }
                    }
                    .item-body {
                        padding: 5px 15px 15px 15px;
                        .title{
                          //  font-family: $font-family-frutiger-bold;
                            margin-bottom: 5px;
                        }
                    }
                }

                a {
                    text-decoration: none;
                    color: #333333;
                }

                .item-wrap {
                    margin: 0 $spotsocial-item-margin 30px $spotsocial-item-margin;
                    transition: box-shadow 0.2s ease-in;
                    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
                    &:hover {
                        -webkit-box-shadow: 15px 15px 20px -5px rgba(0, 0, 0, 0.2);
                        box-shadow: 15px 15px 20px -5px rgba(0, 0, 0, 0.2);
                    }
                }

                .item-head {
                    position: relative;
                    z-index: 1;
                    min-height: 65px;
                }

                .item-body {
                    position: relative;
                    z-index: 1;
                    padding: 25px;
                 //   font-family: $font-family-frutiger-light;

                    text-align: center;
                }

                .picture {

                    .picture-wrap {

                    }

                    img {
                        width: 100%;
                    }
                }

                .provider {
                    position: absolute;
                    z-index: 2;
                    top: 15px;
                    right: 15px;

                    .provider-icon {
                        display: inline-block;
                        //border: 1px solid transparent;

                        object {
                            float: left;
                            max-width: 35px;

                            color: #ffffff;
                        }
                    }
                }

                .profile {

                    .profile-picture {

                        img {
                            display: inline-block;
                            max-width: 48px;
                        }
                    }

                    .profile-name {
                        padding: 8px 0;
                        //font-weight: bold;
                     //   font-family: $font-family-frutiger-roman;
                        color: $theme-alt-color;
                        letter-spacing: 1px;
                    }
                }

                .date {
                    padding: 0 0 8px 0;

                    .date-created {
                        color: #999999;
                        letter-spacing: 1px;
                        font-size: 14px;
                    }
                }

                .content {

                }

                .counters {
                    padding: 20px 0 0 0;

                    text-align: center;
                    color: #aeaeae;

                    .types {
                        display: inline-table;

                        .type {
                            display: table-cell;
                            vertical-align: middle;
                            padding: 0 8px;

                            .icon {
                                display: inline-block;
                                vertical-align: middle;
                                padding: 0 2px 0 0;
                            }

                            &.type-likes{
                                &.type-likes-youtube{
                                    .icon{
                                        *{
                                            fill: $spotsocial-youtube-color;
                                        }
                                    }
                                }
                                &.type-likes-instagram{
                                    .icon{
                                        *{
                                            fill: $spotsocial-instagram-color;
                                        }
                                    }
                                }
                            }

                            .value {
                                display: inline-block;
                                vertical-align: middle;

                                font-weight: bold;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: $screen-lg){
    .spotsocial {
        .aggregator {

            .items{
                .item{
                    width: 33.3334%;
                }
            }
        }
    }
}


@media (max-width: $screen-md){
    .spotsocial {
            .aggregator {

            .items{
                .item{
                    width: 50%;
                }
            }
        }
    }
}
@media (max-width: $screen-sm){
    .spotsocial {
            .aggregator {

            .items{
                .item{
                    width: 100%;
                }
            }
        }
    }
}


@media (max-width: $screen-xs-max){

    .spotsocial {
        .aggregator {
            .items{
                .item{
                    width: 100%;
                    padding: 10px 0;
                }
            }
            .filters {
                .filter {
                    padding: 0 2px;
                    a {
                        min-width: 48px;
                        line-height: 48px;
                        font-size: 15px;
                        letter-spacing: 0px;
                    }
                }
            }
        }

    }
}
