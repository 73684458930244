.fce{
    &.fce-table{
        @extend .table;
        margin-bottom: 1.35em;

        thead {
            background: rgba(203, 203, 203, 0.21);
            //color: $white-color;
            font-family: $font-family-nexa-bold;
            line-height: 35px;

            font-size: 1.2em;
            letter-spacing: 1px;

        }

        td, th {
            //padding: 15px 15px;
            //font-weight: normal;
        }



        th{
            letter-spacing: 1px;
            font-size: 1.05em;
        }
        //Striped rows
        &.fce-table-layout-1{
            @extend .table-striped;
        }
        //Bordered table
        &.fce-table-layout-2{
            @extend .table-bordered;
        }
        //Hover rows
        &.fce-table-layout-3{
            @extend .table-hover;
        }
        //Condensed table
        &.fce-table-layout-4{
            @extend .table-condensed;
        }
        //Basic Table ( 40% - 30% - 30% )
        &.fce-table-layout-5{
            td:nth-child(1){
                width: 40%;
            }
            td:nth-child(2){
                width: 30%;
            }
            td:nth-child(3){
                width: 30%;
            }
        }
        //Basic Table ( 40% - 20% - 20% - 20% )
        &.fce-table-layout-6{
            td:nth-child(1){
                width: 40%;
            }
            td:nth-child(2){
                width: 20%;
            }
            td:nth-child(3){
                width: 20%;
            }
            td:nth-child(4){
                width: 20%;
            }
        }
        //Basic Table ( 20% - 20% - 20% - 20% - 20% )
        &.fce-table-layout-7{
            td:nth-child(1){
                width: 40%;
            }
            td:nth-child(2){
                width: 20%;
            }
            td:nth-child(3){
                width: 20%;
            }
            td:nth-child(4){
                width: 20%;
            }
            td:nth-child(5){
                width: 20%;
            }
        }
        //Striped rows ( 40% - 30% - 30% )
        &.fce-table-layout-8{
            @extend .table-striped;
            td:nth-child(1){
                width: 40%;
            }
            td:nth-child(2){
                width: 30%;
            }
            td:nth-child(3){
                width: 30%;
            }
        }
        //Striped rows ( 40% - 20% - 20% - 20% )
        &.fce-table-layout-9{
            @extend .table-striped;
            td:nth-child(1){
                width: 40%;
            }
            td:nth-child(2){
                width: 20%;
            }
            td:nth-child(3){
                width: 20%;
            }
            td:nth-child(4){
                width: 20%;
            }
        }
        //Striped rows ( 20% - 20% - 20% - 20% - 20% )
        &.fce-table-layout-10{
            @extend .table-striped;
            td:nth-child(1){
                width: 40%;
            }
            td:nth-child(2){
                width: 20%;
            }
            td:nth-child(3){
                width: 20%;
            }
            td:nth-child(4){
                width: 20%;
            }
            td:nth-child(5){
                width: 20%;
            }
        }
        &.small-only {
            display: none;
        }

        &.large-only {

        }
    }
}



@media screen and (max-width: $screen-xs-max) {
    .fce{
        &.fce-table {
            &.small-only {
                display: block;
            }
            &.large-only {
                display: none;
            }
            .st-key{
                padding: 0;
            }
        }
    }
}